import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ElMessage } from "element-plus";
// const getRouter = () => {
//   const files = require.context('@/views', true, /\.vue$/)

//   console.log(files.keys());
// }
// getRouter()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    redirect: '/project',
    children: [
      {
        path: '/project',
        name: 'project',
        component: () => import('../views/project/ProjectIndex.vue'),
        children: [
          {
            path: 'addProject',
            name: 'addProject',
            component: () => import('../views/project/AddProject.vue')
          },]
      },
      {
        path: '/domain',
        name: 'domain',
        component: () => import('../views/domain/DomainIndex.vue'),
        children: [
          {
            path: 'domainGround',
            name: 'domainGround',
            component: () => import('../views/domain/DomainGround.vue')
          },
          // {
          //   path: 'myDomain',
          //   name: 'myDomain',
          //   component: () => import('../views/domain/MyDomain.vue')
          // },
          {
            path: 'domainStore',
            name: 'domainStore',
            component: () => import('../views/domain/DomainStore.vue')
          },]
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('../views/message/MessageIndex.vue')
      },
      {
        path: '/bin',
        name: 'bin',
        component: () => import('../views/Bin/BinIndex.vue')
      },]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/LoginIndex.vue')
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/project',
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/**
 * 全局前置路由守卫，每一次路由跳转前都进入这个 beforeEach 函数
 */
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    // 登录或者注册才可以往下进行
    next();
  } else {
    // 获取 token
    const token = sessionStorage.getItem('Authorization');
    // token 不存在
    if (token === null || token === '') {
      ElMessage.error('您还没有登录，请先登录');
      next('/login');
    } else {
      next();
    }
  }
});

export default router
