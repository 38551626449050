import service from '@/axios'

const add = (data: any) => {
    return service({
        url: '/dict/add',
        method: 'post',
        data
    })
}   





const update = (data: any) => {
    return service({
        url: '/dict/update',
        method: 'post',
        data
    })
}

const list = (data: any) => {
    return service({
        url: '/dict/list',
        method: 'post',
        data
    })
}

const del = (data: any) => {
    return service({
        url: '/dict/del',
        method: 'post',
        data
    })
}


const visible = (data: any) => {
    return service({
        url: '/dict/visible',
        method: 'post',
        data
    })
}


const detail = (params: any) => {
    return service({
        url: `/dict/detail`,
        method: 'get',
        params
    })
}



export default {
    add,
    list,
    del,
    detail,
    update,
    visible
}


