import service from '@/axios'

const add = (data: any) => {
    return service({
        url: '/projectGround/add',
        method: 'post',
        data
    })
}

const update = (data: any) => {
    return service({
        url: '/projectGround/update',
        method: 'post',
        data
    })
}

const list = (data: any) => {
    return service({
        url: '/projectGround/list',
        method: 'post',
        data
    })
}

const del = (data: any) => {
    return service({
        url: '/projectGround/del',
        method: 'post',
        data
    })
}


const detail = (params: any) => {
    return service({
        url: `/projectGround/detail`,
        method: 'get',
        params
    })
}



export default {
    add,
    list,
    del,
    detail,
    update
}


