interface StoreType {
    userInfo: object,
    token: string,
    is_read:boolean
}

// 用户
export default {
    // 开启命名空间
    namespaced: true,
    state: {
        userInfo: {},
        token: "",
        is_read: false
    },
    mutations: {
        SET_USERINFO(state: StoreType, data: any) {
            state.userInfo = data
        },
        SET_READ(state: StoreType, data: any) {
            state.is_read = data
        },
        SET_TOKEN(state: StoreType, data: any) {
            sessionStorage.setItem('Authorization', data)
            state.token = data
        }

    },
    actions: {
        asyncUpdate(store: any, val: any) {
            setTimeout(() => {
                store.commit('updateAge', val)
            }, 2000)
        }
    },
    getters: {
        // format(state: any) {
        //     return state.info.uname + ',nice to meet you~'
        // }
    }
}

