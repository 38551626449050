import service from '@/axios'

const list = (data: any) => {
    return service({
        url: '/message/list',
        method: 'post',
        data
    })
}


const readOne = (data: any) => {
    return service({
        url: '/message/readOne',
        method: 'post',
        data
    })
}



const readAll = (data: any) => {
    return service({
        url: '/message/readAll',
        method: 'post',
        data
    })
}


const removeAll = (data: any) => {
    return service({
        url: '/message/removeAll',
        method: 'post',
        data
    })
}


const isRead = (data: any) => {
    return service({
        url: '/message/isRead',
        method: 'post',
        data
    })
}


export default {
    list,
    readOne,
    readAll,
    isRead,
    removeAll
}


