import service from '@/axios'

const add = (data: any) => {
    return service({
        url: '/project/add',
        method: 'post',
        data
    })
}


const list = (data: any) => {
    return service({
        url: '/project/list',
        method: 'post',
        data
    })
}

const checkUrl = (data: any) => {
    return service({
        url: '/project/checkUrl',
        method: 'post',
        data
    })
}


const getOutUrl = (data: any) => {
    return service({
        url: '/project/getOutUrl',
        method: 'post',
        data
    })
}

const getEntranceUrl = (data: any) => {
    return service({
        url: '/project/getEntranceUrl',
        method: 'post',
        data
    })
}

const detail = (id: any) => {
    return service({
        url: `/project/detail?id=${id}`,
        method: 'get',
    })
}

const update = (data: any) => {
    return service({
        url: '/project/update',
        method: 'post',
        data
    })
}

const del = (data: any) => {
    return service({
        url: '/project/del',
        method: 'post',
        data
    })
}


const delProject = (data: any) => {
    return service({
        url: '/project/delProject',
        method: 'post',
        data
    })
}



const binList = (data: any) => {
    return service({
        url: '/project/binList',
        method: 'post',
        data
    })
}

const toBin = (data: any) => {
    return service({
        url: '/project/toBin',
        method: 'post',
        data
    })
}


const toGround = (data: any) => {
    return service({
        url: '/project/toGround',
        method: 'post',
        data
    })
}



const changeGround = (data: any) => {
    return service({
        url: '/project/changeGround',
        method: 'post',
        data
    })
}


const updateRemarks = (data: any) => {
    return service({
        url: '/project/updateRemarks',
        method: 'post',
        data
    })
}

const updateSort = (data: any) => {
    return service({
        url: '/project/updateSort',
        method: 'post',
        data
    })
}

const random = (data: any) => {
    return service({
        url: '/project/random',
        method: 'post',
        data
    })
}


const getPvOrUv = (params: any) => {
    return service({
        url: `/project/getPvOrUv`,
        method: 'get',
        params
    })
}


const getPvOrUvTotal = () => {
    return service({
        url: `/project/getPvOrUvTotal`,
        method: 'get',
    })
}


const dayDetail = (data: any) => {
    return service({
        url: '/project/detail',
        method: 'post',
        data
    })
}


export default {
    add,
    list,
    detail,
    update,
    del,
    delProject,
    binList,
    toBin,
    toGround,
    changeGround,
    updateRemarks,
    checkUrl,
    getOutUrl,
    getEntranceUrl,
    updateSort,
    random,
    getPvOrUv,
    getPvOrUvTotal,
    dayDetail
}


