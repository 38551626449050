import domain from './domain'
import users from './users'
import dict from './dict'
import project from './project'
import projectGround from './projectGround'
import focus from './focus'
import message from './message'
import cdn from './cdn'
import gzh from './gzh'

const api = {
    domain,
    users,
    dict,
    project,
    projectGround,
    focus,
    message,
    cdn,
    gzh
}

export default api

