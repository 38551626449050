import service from '@/axios'

const list = (data: any) => {
    return service({
        url: '/gzh/list',
        method: 'post',
        data
    })
}


const add = (data: any) => {
    return service({
        url: '/gzh/add',
        method: 'post',
        data
    })
}


const del = (data: any) => {
    return service({
        url: '/gzh/del',
        method: 'post',
        data
    })
}

export default {
    list,
    add,
    del
}


