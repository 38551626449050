import service from '@/axios'

const getConfig = () => {
    return service({
        url: '/cdn/getConfig',
        method: 'get'
    })
}   

const update = (data: any) => {
    return service({
        url: '/cdn/update',
        method: 'post',
        data
    })
}


export default {
    getConfig,
    update
}


