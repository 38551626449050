
import axios from 'axios'
import { ElMessage, ElLoading } from 'element-plus'
let loadingInstance: any;
console.log(process.env.VUE_APP_API_URL);

const service = axios.create({
    // baseURL: "http://8.210.168.57:3000", //请求后端数据的基本地址，自定义
    baseURL: process.env.VUE_APP_API_URL, //请求后端数据的基本地址，自定义
    timeout: 60000                   //请求超时设置，单位ms
})


// 请求拦截
service.interceptors.request.use(
    (config: any) => {
        if (!config.url.includes('checkUrl')) {
            loadingInstance = ElLoading.service({
                text: "加载中，请等待"
            })
        }
        if (sessionStorage.getItem('Authorization')) {
            config.headers.Authorization = sessionStorage.getItem('Authorization')
        }
        return config
    },
    (error: any) => {
        loadingInstance.close()
        return Promise.reject(error)
    }
)

// 响应拦截
service.interceptors.response.use(
    (response: any) => {
        loadingInstance.close()
        const res = response.data
        if (res.code !== 1 || res.code !== 200) {
            return response.data
        } else {
            return response.data
        }
    },
    (error: any) => {
        loadingInstance.close()
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    ElMessage({
                        message: "服务器错误，请稍后重试",
                        type: "error",
                        duration: 5 * 1000
                    })
                    break
                default:
                    if (error.response.data.error == "invalid_grant") {
                        ElMessage({
                            message: error.response.data.error_description,
                            type: "error"
                        })
                    }
                    return Promise.reject(error)
            }
        }
    }
)

export default service