import service from '@/axios'

const update = (data: any) => {
    return service({
        url: '/focus/update',
        method: 'post',
        data
    })
}

const list = (data: any) => {
    return service({
        url: '/focus/list',
        method: 'post',
        data
    })
}


export default {
    list,
    update
}


