import service from '@/axios'

const batchAdd = (data: any) => {
    return service({
        url: '/domain/batchAdd',
        method: 'post',
        data
    })
}


const list = (data: any) => {
    return service({
        url: '/domain/list',
        method: 'post',
        data
    })
}


const list2 = (data: any) => {
    return service({
        url: '/domain/list2',
        method: 'post',
        data
    })
}

const update = (data: any) => {
    return service({
        url: '/domain/update',
        method: 'post',
        data
    })
}


const isSwitch = (data: any) => {
    return service({
        url: '/domain/isSwitch',
        method: 'post',
        data
    })
}


const check = (data: any) => {
    return service({
        url: '/domain/check',
        method: 'post',
        data
    })
}

const checkNewUrl = (data: any) => {
    return service({
        url: '/domain/checkNewUrl',
        method: 'post',
        data
    })
}


const del = (data: any) => {
    return service({
        url: '/domain/del',
        method: 'post',
        data
    })
}

const getMeta = (data: any) => {
    return service({
        url: '/domain/getMeta',
        method: 'post',
        data
    })
}


const getWxName = (data: any) => {
    return service({
        url: '/domain/getWxName',
        method: 'post',
        data
    })
}



const checkDomain = (data: any) => {
    return service({
        url: '/domain/checkDomain',
        method: 'post',
        data
    })
}


const checkGZHUrl = (data: any) => {
    return service({
        url: '/domain/checkGZHUrl',
        method: 'post',
        data
    })
}

const isSuccess = (data: any) => {
    return service({
        url: '/domain/isSuccess',
        method: 'post',
        data
    })
}



export default {
    batchAdd,
    list,
    list2,
    update,
    isSwitch,
    check,
    checkNewUrl,
    del,
    getMeta,
    getWxName,
    checkDomain,
    checkGZHUrl,
    isSuccess
}


