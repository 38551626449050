import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/index.scss'
import $api from '@/api/index'

const app = createApp(App).use(store).use(ElementPlus).use(router)
app.config.globalProperties.$api = $api
app.mount('#app')

const state: any = store.state
// 注册（函数形式的指令）
app.directive('isAdmin', (el) => {
    if (!state.user.userInfo.role) {
        el.style.display = 'none'
    }
})

